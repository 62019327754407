.contact {
  .content--wrapper {
    margin: 0 auto;
    text-align: center;
    width: 80%;

    h1 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .socials {
      display: flex;
      gap: 1rem;
      justify-content: center;

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          color: #007bff;
        }
      }
    }
  }
}