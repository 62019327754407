@import "./../../public/_variables";
@import "./../../public/_mixins";

section#company {
  div.content--wrapper {
    background-color: $bg--dark;

    #company--quote {
      padding-block: $spacing--h-md;
    }

    #company--description {}

    .image-container {
      background-image: url("../../public/company/portrait01.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      min-height: 200px;
      height: 50vw;
      margin: $spacing--h-sm $spacing--w-sm;
      position: relative;
      width: calc(100% - $spacing--w-md);
    }

    .text--quote {
      align-items: center;
      background-color: $bg--dark;
      color: $c--light;
      display: flex;
      font-style: italic;
      margin-inline: 3rem 2rem;
      position: relative;
      text-align: center;
      z-index: 1;

      .double_quotes {
        height: 150px;
        opacity: 0.05;
        position: absolute;
        rotate: 180deg;
        z-index: -1;
      }
    }

    .text--company {
      margin-inline: $spacing--w-sm;
      text-align: justify;

      h1 {
        text-align: center;
      }
    }


    @include for-desktop {
      padding-block-start: $spacing--h-xl;

      #company--quote {
        padding-block-end: $spacing--h-md;
        padding-block-start: $spacing--h-sm;
      }

      #company--description {
        padding-block-end: $spacing--h-md;
      }

      .image-container {
        background-size: cover;
        height: 100vw;
        margin: 0 auto;
        max-height: min(750px, 50vw);
        max-width: min(540px, 100vh);
        position: absolute;
        right: $spacing--w-md;
        top: -$spacing--h-xxxl;
        width: 100vh;
      }

      .text--quote {
        margin-inline: 0 $spacing--w-md;
        text-align: left;

        .double_quotes {
          left: -2rem;
        }
      }

      .text--company {
        margin-block-start: $spacing--h-sm;
        margin-inline: 0 $spacing--w-md;

        text-align: left;

        h1 {
          text-align: left;
        }
      }
    }
  }
}

button {
  align-items: center;
  background-color: transparent;
  border: none;

  // transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: $spacing--h-xxxs $spacing--w-xxs;
  position: relative;

  &::before {
    border: 1px solid rgba($c--dark, 0.3);
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    // transition: all 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &::after {
    border: 1px solid rgba($c--dark, 0.3);
    content: '';
    height: 100%;
    position: absolute;
    transition: all 0.5s cubic-bezier(.47, 1.64, .41, .8);
    width: 100%;
  }

  &:hover {
    &::before {
      // width: calc(100% - 0.5rem);
    }

    &::after {
      height: calc(100% + 0.4rem);
      width: calc(100% - 0.4rem);
    }
  }
}