@mixin for-desktop {
  @media screen and (min-width: $bp--w-xxl) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (min-width: $bp--w-md) and (max-width: calc($bp--w-xxl - 1px)) {
    @content;
  }
}

@mixin for-mobile {
  @media screen and (max-width: calc($bp--w-md - 1px)) {
    @content;
  }
}