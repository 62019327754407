@import "./../../public/_variables";
@import "./../../public/_mixins";

section#projects {
  .content--wrapper {
    position: relative;
    // width: min(1500px, 100%);
    text-align: center;

    &:first-child {
      padding-block-start: $spacing--w-sm;
    }

    &:not(:first-child) {
      margin-block-start: $spacing--w-xs;
    }

    &:not(:nth-child(-n+2)) {
      margin-block-start: $spacing--w-md;
    }

    #project--video {

      padding-inline: $spacing--w-sm;

      .video--wrapper {
        margin-inline: auto;
        width: 100%;

        video {
          object-fit: cover;
          position: relative;
          width: 100%;
        }

        .project--title--wrapper {
          background-color: $bg--dark;
          color: $c--light;
          padding-block: 1rem;
          padding-inline: 1.35rem;
          text-align: left;

          .project--title-creation {
            color: $c--primary;
            display: none;
            font-size: 0.8rem;
            font-style: italic;
            height: 22px;
            margin-left: 1rem;
          }

          .project--title {
            height: 1.1rem;
          }

          .project--subtitle {
            margin-block: $spacing--h-xxxs;
          }

          .project--time {
            font-size: 0.9rem;
            opacity: 0.7;
          }
        }
      }
    }

    #project--description {
      padding-inline: $spacing--w-sm;
      z-index: 2;

      .content--col--left--rfull {
        .text--project {
          display: flex;
          flex-direction: column;
          margin-block-start: 2vh;
          // height: calc(100% + (725px/2));
          text-align: justify;

          .project--title {
            display: none;
            margin-bottom: 0;
          }

          .project--subtitle {
            display: none;
            margin-bottom: 0;
            margin-top: $spacing--h-xxs;
          }

          .project--time {
            display: none;
            font-size: 0.8em;
            margin-bottom: $spacing--h-xxs;
            opacity: 0.7;
          }

          .project--description {
            margin-top: 1rem;

            p:not(:last-child) {
              margin-bottom: 1rem;
            }
          }
        }
      }

      .content--col--right--rfull {
        .image-container {
          // background-image: url("../../public/projects/comme_un_echo/bg_sm_comme_un_echo.jpg");
          background-position: center;
          background-size: cover;
          display: flex;
          height: 75vw;
          margin-top: $spacing--h-sm;
          position: relative;
          width: 100%;
        }
      }
    }

    #project--gallery--wrapper {
      margin-block-start: $spacing--h-sm;
      padding-block: $spacing--h-sm;

      #project--gallery {
        // flex-wrap: wrap;

        // display: grid; // Change this line
        // grid-template-columns: repeat(3, 1fr); // Add this line
        // grid-auto-rows: auto; // Add this line
        align-items: center; // Add this line
        display: flex;
        height: min(calc(150px + 2rem), 25vh);
        margin-inline: auto;
        // justify-content: center;
        margin-top: $spacing--h-xs;
        // margin-top: $spacing--h-xs;
        // margin-inline: auto;
        // position: relative;

        // display: grid;
        // gap: 10px;
        // grid-template-columns: repeat(auto-fill, minmax(calc(calc(100% - 2rem) / 3), 1fr));
        // grid-template-rows: masonry;

        max-width: calc(100% - 4rem);
        overflow: auto;
        padding-inline: 1rem;
        position: relative;

        scroll-snap-type: both proximity;

        .project--gallery--img--wrapper {
          height: 100%;
          max-height: min(150px, 25vh);
          padding-inline: 0.5rem;
          scroll-snap-align: end;

          // flex: 0 0 auto;
          // max-width: calc(100% / 3);
          width: fit-content;

          &:first-child {
            // padding-inline-start: 1rem;
            // scroll-snap-align: start;
          }

          &:last-child {
            // padding-inline-end: 1rem;
            scroll-snap-align: start;
          }


          img {
            height: 100%;
            object-fit: contain;
            transition: all 0.25s ease-in-out;
            width: auto;
          }

        }
      }
    }

    #project--legal-notices--wrapper {
      padding-block-end: $spacing--h-sm;

      b {
        font-size: 0.7em;
        font-weight: 300;
        opacity: 0.7;
        text-transform: uppercase;

        &:not(:first-child) {
          margin-block-start: $spacing--h-xxs;
        }
      }
    }

    @include for-desktop {

      &:first-child {
        margin-block-start: $spacing--w-md; // Remover after animation
        padding-block-start: $spacing--w-sm;
        padding-block-end: 0;
      }

      &:nth-child(n + 3) {
        margin-block-start: $spacing--w-md; // Remover after animation
      }

      >h1 {
        text-align: left;
        margin-inline: auto;
        padding-inline: 0;
        width: min(1500px, calc(100% - (2 * $spacing--w-sm)));

      }

      #project--video {
        margin-inline: auto;
        padding-inline: 0;
        width: min(1500px, calc(100% - (2 * $spacing--w-sm)));

        .video--wrapper {
          height: 600px;
          margin-block-start: $spacing--h-sm;
          width: min(1500px, 100%);

          video {
            height: 100%;
          }

          .project--title--wrapper {
            background-color: rgba($bg--dark, 0.95);
            left: min(0, -$spacing--w-xs);
            position: absolute;
            // bottom: 105px;
            top: 175px;
            width: 350px;
            z-index: 1;

            .project--title-creation {
              display: block;
            }

            .project--subtitle,
            .project--time {
              display: none;
            }
          }
        }
      }

      #project--description {
        margin-inline: auto;
        padding-inline: 0;
        width: min(1500px, 100%);

        .content--col--left--rfull {
          display: flex;
          justify-content: flex-end;

          .text--project {
            margin-block-end: $spacing--h-sm;
            // margin-inline: 0;
            margin-block-start: $spacing--h-xl;
            margin-inline: $spacing--w-sm;
            width: min(600px, 100%);

            .project--title {
              display: block;
            }

            .project--subtitle {
              display: block;
              margin-top: 0;
            }

            .project--time {
              display: block;
              margin-bottom: 0;
            }
          }
        }

        .content--col--right--rfull {
          .image-container {
            background-size: cover;
            bottom: -$spacing--h-xxl;
            height: 100vh;
            margin: 0 auto;
            max-height: min(750px, 50vw);
            max-width: min(540px, 100vh);
            position: absolute;
            right: $spacing--w-xs;
            width: 100vw;
          }
        }
      }

      #project--gallery--wrapper {
        margin-block-start: $spacing--h-md;
        padding-block-start: $spacing--h-xl;

        #project--gallery {
          .project--gallery--img--wrapper {
            img:hover {
              cursor: pointer;
              scale: 1.1;
            }
          }
        }
      }

      #project--legal-notices--wrapper {
        padding-block-end: $spacing--h-sm;

        b {
          font-size: 0.7em;
          font-weight: 300;
          opacity: 0.7;
          text-transform: uppercase;

          &:not(:first-child) {
            margin-block-start: $spacing--h-xxs;
          }
        }
      }
    }
  }
}