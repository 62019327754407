@import "./../../public/_variables";
@import "./../../public/_mixins";

section#team {
  .content--wrapper {
    margin-block-start: -$spacing--h-xl * 2.25;
    margin-inline: auto;
    padding-block-start: $spacing--w-xxs;
    padding-inline: $spacing--w-sm;
    width: min(1350px, 100%);

    .team--title {
      margin-block-end: $spacing--h-sm;
      margin-block-start: $spacing--h-sm;
      text-align: center;
    }

    .team--members--list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .team--members--item {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-block-end: $spacing--h-sm;

        width: max(325px, 33.33%);

        img {

          box-shadow: 0 0.35rem 0.75rem rgba($c--dark, 0.3);
          height: 100%;
          max-height: 390px;
          max-width: 290px;
          object-fit: cover;
          width: 100%;
          // overflow: hidden;

          transition: all 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            scale: 1.025;
          }
        }

        .team--members--legend {
          margin-block-start: $spacing--h-xxs;
          text-align: center;

          h3 {
            margin-block: $spacing--h-xxxs;
            text-transform: initial;
          }

          ul {
            align-items: center;
            display: flex;
            font-size: 0.9rem;
            font-weight: 500;
            justify-content: center;
            opacity: 0.7;
            // margin-block-end: $spacing--h-xxxs;
            text-transform: uppercase;

            li {
              align-items: center;
              display: flex;
              flex-wrap: nowrap;


              &:not(:last-child)::after {
                content: "•";
                display: inline-block;
                margin-inline: 0.25rem;
              }
            }

            // span:not(:first-child) {

            //   // content: "•"
            // }
          }

          .team--members--description {
            // display: none;
            margin-block-start: $spacing--h-xxs;
            text-align: justify;
            // opacity: 0;
            // height: 0;
            overflow: hidden;

            transition: all 0.6s ease-in-out;
          }

          .team--members--social {
            align-items: center;
            display: flex;
            gap: 0.5rem;
            justify-content: center;
            margin-block-start: $spacing--h-xxs;

            a {
              color: $c--dark;
              font-size: 1.35rem;
              transition: all 0.2s ease-in-out;

              &:hover {
                scale: 1.5;
              }
            }
          }
        }
      }
    }

    // @include for-tablet {

    //   .team--members--list {
    //     .team--members--item {
    //       max-width: calc(40% - 3rem);
    //       // margin-inline: 1rem;
    //       flex-direction: column;

    //       .team--members--legend {
    //         .team--members--social {
    //           a {
    //             font-size: 1.1rem;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // @include for-desktop {
    //   .team--members--list {
    //     .team--members--item {
    //       max-width: calc(33.33% - 3rem);
    //       // margin-inline: 1rem;
    //       // flex-direction: column;

    //       .team--members--legend {
    //         .team--members--social {
    //           a {
    //             font-size: 1.1rem;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}