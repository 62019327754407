@import "./../../public/_variables";
@import "./../../public/_mixins";

footer {
  background-color: $c--dark;
  color: $c--light;
  display: flex;
  flex-direction: column;
  line-height: 1.1rem;
  margin-block-start: $spacing--h-sm;
  overflow: hidden;
  padding-block-end: $spacing--h-xxxs;
  padding-block-start: $spacing--h-sm;
  position: relative;

  #bg--footer {
    display: flex;
    position: absolute;
    top: 0;
    z-index: 0;

    &::after {
      background: linear-gradient(to top, rgba($c--dark, 1) 50%, rgba($c--dark, 0.9) 100%);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      width: 100%;
    }
  }

  a {
    color: $c--light;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1 !important;
    }
  }

  .contact--wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .title {
      font-size: 0.9rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    .email {
      font-size: 0.9rem;
      font-weight: 200;
      opacity: 0.7;
    }
  }

  .socials--wrapper {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-block-start: $spacing--h-xxs;
    z-index: 1;

    a {

      &:hover {
        color: $c--primary;
        scale: 1.25;
      }
    }
  }

  .subcontact--wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-block-start: $spacing--h-xxs;
    z-index: 1;


    .name {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .role {
      color: $c--primary;
      font-size: 0.8rem;
      font-weight: 300;
      text-transform: uppercase;
    }

    .email {
      font-size: 0.9rem;
      font-weight: 200;
      opacity: 0.7;
    }

    .phone {
      font-size: 0.9rem;
      font-weight: 200;
      opacity: 0.7;
    }
  }

  .copyright--wrapper {
    display: flex;
    justify-content: center;
    margin-block-start: $spacing--h-sm;
    opacity: 0.7;
    z-index: 1;

    font: {
      size: 0.65rem;
      weight: 200;
    }
  }
}