@import "./../../public/_variables";
@import "./../../public/_mixins";

#comme_un_echo.content--wrapper {
  #project--description {
    .content--col--right--rfull {
      .image-container {
        background-image: url("../../public/projects/comme_un_echo/bg_sm_comme_un_echo.jpg");
        // background-position: center;
        // background-size: cover;
        // display: flex;
        // height: 75vw;
        // margin-top: $spacing--h-sm;
        // position: relative;
        // width: 100%;
      }
    }
  }
}