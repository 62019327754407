@import "./../../public/_variables";
@import "./../../public/_mixins";

section[id^=member_detail_] {

  padding-inline: $spacing--w-sm;

  img {

    box-shadow: 0 0.35rem 0.75rem rgba($c--dark, 0.3);
    // height: 100%;
    // max-height: 390px;
    // max-width: 290px;
    object-fit: cover;
    width: 100%;
    // overflow: hidden;
  }

  .member--title--wrapper {
    background-color: $bg--dark;
    color: $c--light;
    padding-block: 1rem;
    padding-inline: 1.35rem;
    margin-block: $spacing--h-xxs;
    text-align: left;

    >h3 {
      margin-block: $spacing--h-xxxs;
      text-transform: initial;
    }

    ul {
      align-items: center;
      display: flex;
      font-size: 0.9rem;
      font-weight: 300;
      margin-block-end: $spacing--h-xxxs;
      text-transform: uppercase;
      flex-wrap: wrap;

      li {
        color: $c--primary;
        align-items: center;
        display: flex;
        flex-wrap: nowrap;

        &:not(:last-child)::after {
          content: "•";
          display: inline-block;
          margin-inline: 0.25rem;
        }
      }
    }

  }

  .member--description {
    text-align: justify;
  }

  .member--social {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-block-start: $spacing--h-xs;

    a {
      color: $c--dark;
      font-size: 1.35rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        scale: 1.5;
      }
    }
  }
}