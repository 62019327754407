@import "./../../public/_variables";
@import "./../../public/_mixins";

section#agenda {

  #agenda--wrapper {
    padding-block-end: $spacing--h-sm;
    padding-block-start: $spacing--h-md;
    padding-bottom: $spacing--h-xxxl * 1.5;
    padding-inline: $spacing--w-sm;

    img {
      margin-inline: auto;
      width: min(1200px, 100%);
    }
  }
}