// BREAKPOINTS
$bp--w-sm: 576px;
$bp--w-md: 768px;
$bp--w-lg: 992px;
$bp--w-xl: 1200px;
$bp--w-xxl: 1400px;

$bp--h-xxl: 900px;
$bp--h-xl: calc($bp--h-xxl / ($bp--w-xxl/$bp--w-xl));
$bp--h-lg: calc($bp--h-xl / ($bp--w-xl/$bp--w-lg));
$bp--h-md: calc($bp--h-lg / ($bp--w-lg/$bp--w-md));
$bp--h-sm: calc($bp--h-md / ($bp--w-md/$bp--w-sm));

// COLORS
// $bg--light: rgb(242 247 245);
$bg--light: rgb(244, 244, 244);
// $bg--dark: rgb(20, 25, 31);
$bg--dark: rgb(27, 33, 39);

$c--light: rgb(242 247 245);
// $c--dark: rgb(20, 25, 31);
$c--dark: rgb(27, 33, 39);

$c--primary: #cdab70;
$c--secondary: #42ae98;
// $c-tertiary: #3cb371;

//Text Shadows
$txt--shadow-sm: 0.05rem 0.05rem 0.1rem rgba($c--dark, 0.5);

// Spacing
$spacing--xxxs: 0.35rem;
$spacing--xxs: 0.5rem;
$spacing--xs: 0.8rem;
$spacing--sm: 1rem;
$spacing--md: 1.5rem;
$spacing--lg: 2rem;

$spacing--w-xxxs: 0.75vw;
$spacing--w-xxs: 2vw;
$spacing--w-xs: 3vw;
$spacing--w-sm: 5vw;
$spacing--w-md: 10vw;
$spacing--w-lg: 12vw;
$spacing--w-xl: 15vw;
$spacing--w-xxl: 20vw;
$spacing--w-xxxl: 25vw;

$spacing--h-xxxs: 0.75vh;
$spacing--h-xxs: 2vh;
$spacing--h-xs: 3vh;
$spacing--h-sm: 5vh;
$spacing--h-md: 10vh;
$spacing--h-lg: 12vh;
$spacing--h-xl: 15vh;
$spacing--h-xxl: 20vh;
$spacing--h-xxxl: 25vh;