@import "./../../public/_variables";
@import "./../../public/_mixins";

section#hero {
  background-color: $bg--dark;
  height: 100vh;
  padding-block-start: 0;

  @include for-desktop {
    padding-block-start: 2.5rem;
  }

  div.content--wrapper {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin-inline: auto;
    overflow: clip;
    position: relative;
    width: 100vw;

    @include for-desktop {
      height: calc(100vh - 6rem);
      width: calc(100vw - 4rem);
    }

    &:after {
      background-color: rgba(0, 0, 0, 0.35);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    >video {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    >img {
      max-height: 100%;
      position: absolute;
      width: min(60vw, 400px);
      z-index: 2;
    }

    >.square--1 {
      animation: spin 100s linear infinite;
      border: 1px solid rgba($c--light, 0.45);
      height: 55vh;
      position: absolute;
      width: 55vh;
      z-index: 2;
    }

    >.square--2 {
      animation: rspin 100s linear infinite;
      border: 1px solid rgba($c--light, 0.45);
      height: 55vh;
      position: absolute;
      width: 55vh;
      z-index: 2;
    }
  }

}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg) scale();
  }
}

@-moz-keyframes rspin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rspin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rspin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}