@import "./../../public/_variables";
@import "./../../public/_mixins";

.hamburger-react {
  pointer-events: auto;
  position: absolute;
  z-index: 999;

  @include for-desktop {
    display: none;
  }

  &>div {
    box-shadow: 0 0 0.05rem rgba($c--dark, 0.35);
    z-index: 999;
  }
}

nav#mainmenu {
  position: fixed;
  right: 0.5rem;
  z-index: 999;

  #member--btn-back {
    align-items: center;
    color: $c--light;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    left: 0;
    margin-block-start: auto;
    margin-left: 1rem;
    margin-top: 1rem;
    opacity: 0;
    position: fixed;

    text-align: justify;
    text-transform: uppercase;

    top: 0;
    transform: translateX(100vw);

    transition: all 0.35s ease-out;
    width: fit-content;
    z-index: 1;

    &.active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.35s ease-out;
    }

    >img {
      height: 2rem;
      transform: rotate(180deg);
    }

    @include for-desktop {
      display: none;
    }
  }

  @include for-desktop {
    pointer-events: none;
  }

  ul {
    background-color: rgba(0, 0, 0, 0.9);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    min-height: 100dvh;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;

    transition: all 0.25s ease-in-out;
    width: 100%;
    z-index: -1;

    li {
      transform: scale(0.8) translateY(-100vh);
      transition: all 0.45s ease-in-out;
    }

    &.open {
      opacity: 1;
      pointer-events: auto;
      z-index: 1;

      li {
        transform: scale(1) translateY(0);
      }
    }

    @include for-desktop {
      background-color: rgba($c--dark, 0.8);

      bottom: auto;
      // background-color: transparent;
      flex-direction: row;
      height: 2.5rem;
      min-height: initial;
      opacity: 1;

      li {
        transform: none;
      }
    }
  }

  a {
    align-items: center;
    color: $c--light;
    display: flex;
    font-size: 1.75rem;
    font-weight: 400;
    height: 60px;
    justify-content: center;
    pointer-events: auto;

    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    text-transform: uppercase;

    transition: color 0.2s ease-out;
    width: 100%;

    &:hover {
      color: rgba($c--light, 0.5);
    }

    @include for-desktop {
      color: $c--light;
      display: block;
      font-size: 0.9rem;
      height: auto;
      padding: 0.5rem 0.35rem;
      text-decoration: none;
      text-shadow: $txt--shadow-sm;
    }
  }
}